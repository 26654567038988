import {
  Button,
  Card,
  Divider,
  Stack,
  Typography
} from "@mui/material";
import { Box, Container } from "@mui/system";
import React, { useRef } from "react";
import Barcode from "react-barcode";
import { exportComponentAsJPEG } from "react-component-export-image";
import SurveyBrandType from "../../constants/survey-brand-type";
import Logo from "../../pages/main/Logo";
  
  function Format1000k({ brand, code}) {
    const componentRef = useRef(null);
  
    return (
      <form
        style={{
          minHeight: "100vh",
          height: "100%",
          background: `${
            SurveyBrandType.TOKYOLIFE === brand ? "#FFEDE8" : " #FAFAFA"
          }`,
        }}
      >
        <Box
          sx={{
            display: "flex",
            height: "100%",
            pb: 3,
          }}
        >
          <Container maxWidth="md">
            <Logo brand={brand} />
            <Box>
              <Card>
                <Stack
                  alignItems="center"
                  sx={{
                    p: 2,
                    borderTop: `6px solaid ${
                      SurveyBrandType.TOKYOLIFE === brand ? "#C81F24" : "#656565"
                    }`,
                  }}
                >
                  <Typography variant="h5">
                    <strong>Format đăng ký nhận quà</strong>
                  </Typography>
                </Stack>
                <Divider />
                <Box sx={{ p: 2, textAlign: "center" }}>
                  <div ref={componentRef}>
                    <Typography variant="h6">
                    CHÚC MỪNG QUÝ KHÁCH NHẬN ĐƯỢC ƯU ĐÃI CODE 1 TRIỆU THÀNH CÔNG TẠI FORMAT!
                    </Typography>
                    <Typography >
                    (Chương trình phát triển thị trường, hợp tác đối tác)
                    </Typography>
                    <Typography
                      style={{
                        fontStyle: "italic",
                        fontWeight: "bold",
                        color: "red",
                      }}
                    >
                      Quý khách vui lòng lưu lại mã này để áp dụng ưu đãi.  
                    </Typography>
                    <Typography>
                      <Barcode value={code} />
                    </Typography>
                    <Typography>
                    FORMAT gửi tặng Qúy khách 01 Mã Code 1 TRIỆU áp dụng cho hóa đơn từ 2 TRIỆU trở lên.    
                    </Typography>
                    <Typography>
                    Áp dụng với các sản phẩm Trang phục, giày, túi, ví, dây lưng nam nữ nguyên giá.
                    </Typography>
                    <Typography>
                    (Trừ Áo Dài, FirstClass, giày Kobe, giày êm chân, SUNSTOP COOLING  Nam Nữ, Đồ Lót)
                    </Typography>
                    <Typography>
                    Mỗi Qúy khách mua hàng nhận ưu đãi 01 lần trong suốt thời gian chương trình.
                    </Typography>
                    <Typography>
                    Không áp dụng cùng ưu đãi thẻ vip, ưu đãi khác. 
                    </Typography>
                    
                    <Typography>HSD: Mã ưu đãi đến hết 05/05/2024.</Typography>
                    <Typography>Tổng đài CSKH : 18006136</Typography>
                      <Typography>
                        Website:{" "}
                        <a href="https://format.com.vn">https://format.com.vn</a>
                      </Typography>
                  </div>
                  <p style={{ textAlign: "center" }}>
                    <Button
                      variant="outlined"
                      onClick={(e) => {
                        e.preventDefault();
                        return exportComponentAsJPEG(componentRef);
                      }}
                      sx={{ mt: 2 }}
                    >
                      Tải về máy
                    </Button>
                  </p>
                </Box>
              </Card>
            </Box>
          </Container>
        </Box>
      </form>
    );
  }
  
  export default Format1000k;
  